/* PC, 태블릿 환경을 위한 스타일 */
@media (min-width: 768px) {
    .title {
        margin: 0;
        font-size: 24px;
        font-weight: bold;
    }

    .description {
        font-size: 14px;
        color: var(--sub-font-color);
        margin: 0;
    }

    .nickname_input {
        font-size: 18px;
        color: var(--main-font-color);
        border: none;
        border-bottom: 1px solid var(--sub-20-color);
        width: 100%;
        padding: 8px 0;
    }

    .birthday_select_container {
        display: flex;
    }

    .birthday_select {
        width: auto;
        flex-grow: 1;
        font-size: 18px;
    }

    .sex_btn_container {
        display: flex;
    }

    .sex_btn {
        border-radius: 10px;
        border: 0px;
        width: auto;
        flex-grow: 1;
        height: 60px;
        color: white;
        background-color: var(--main-color);
        font-size: 18px;
    }

    .sex_btn_unselected {
        border-radius: 10px;
        border: 0px;
        width: auto;
        flex-grow: 1;
        height: 60px;
        color: var(--sub-font-color);
        background-color: var(--sub-5-color);
        font-size: 18px;
    }

    .address_select {
        font-size: 18px;
    }

    .submit_btn_inactive{
        border-radius: 10px;
        border: 0px;
        width: 100%;
        height: 60px;
        color: #ffffff;
        background-color: var(--main-color);
        opacity: 0.5;
        font-size: 18px;
    }

    .submit_btn_active{
        border-radius: 10px;
        border: 0px;
        width: 100%;
        height: 60px;
        color: #ffffff;
        background-color: var(--main-color);
        font-size: 18px;
    }
}

/* 모바일 환경을 위한 스타일 */
@media (max-width: 767px) {
    .title {
        margin: 0;
        font-size: 22px;
        font-weight: bold;
    }

    .description {
        font-size: 12px;
        color: var(--sub-font-color);
        margin: 0;
    }

    .nickname_input {
        font-size: 16px;
        color: var(--main-font-color);
        border: none;
        border-bottom: 1px solid var(--sub-20-color);
        width: 100%;
        padding: 8px 0;
    }

    .birthday_select_container {
        display: flex;
    }

    .birthday_select {
        width: auto;
        flex-grow: 1;
        font-size: 16px;
    }

    .sex_btn_container {
        display: flex;
    }

    .sex_btn {
        border-radius: 10px;
        border: 0px;
        width: auto;
        flex-grow: 1;
        height: 60px;
        color: white;
        background-color: var(--main-color);
        font-size: 16px;
    }

    .sex_btn_unselected {
        border-radius: 10px;
        border: 0px;
        width: auto;
        flex-grow: 1;
        height: 60px;
        color: var(--sub-font-color);
        background-color: var(--sub-5-color);
        font-size: 16px;
    }

    .address_select {
        font-size: 16px;
    }

    .submit_btn_inactive{
        border-radius: 10px;
        border: 0px;
        width: 100%;
        height: 60px;
        color: #ffffff;
        background-color: var(--main-color);
        opacity: 0.5;
        font-size: 16px;
    }

    .submit_btn_active{
        border-radius: 10px;
        border: 0px;
        width: 100%;
        height: 60px;
        color: #ffffff;
        background-color: var(--main-color);
        font-size: 16px;
    }
}