/* PC, 태블릿 환경을 위한 스타일 */
@media (min-width: 768px) {
    .container {
        margin: auto;
        margin-bottom: 20px;
        width: 400px;
        display: flex;
        flex-direction: column;
        padding: 20px;
        background-color: var(--sub-5-color);
        border-radius: 10px;
    }

    .title {
        text-align: center;
        margin: 0;
        font-size: 18px;
        font-weight: bold;
    }

    .type_container {
        display: flex;
    }

    .type_btn{
        background-color: var(--sub-10-color);
        flex: 1;
        padding: 20px;
        height: 100px;
        border-radius: 5px;
        text-align: center;
    }

    .type_btn_container{
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .type_btn_text{
        text-align: center;
        margin: 0;
        font-size: 16px;
        font-weight: bold;
    }
}

/* 모바일 환경을 위한 스타일 */
@media (max-width: 767px) {
    .container {
        margin: auto;
        margin-bottom: 20px;
        width: auto;
        display: flex;
        flex-direction: column;
        padding: 20px;
        background-color: var(--sub-5-color);
        border-radius: 10px;
    }

    .title {
        text-align: center;
        margin: 0;
        font-size: 16px;
        font-weight: bold;
    }

    .type_container {
        display: flex;
        flex-direction: column;
    }

    .type_btn{
        background-color: var(--sub-10-color);
        flex: 1;
        padding: 20px;
        height: 100px;
        border-radius: 5px;
        text-align: center;
    }

    .type_btn_container{
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .type_btn_text{
        text-align: center;
        margin: 0;
        font-size: 14px;
        font-weight: bold;
    }
}