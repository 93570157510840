.loader {
    margin: auto;
    border-radius: 50%;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: translateY(0%) rotate(0deg);
    }
    100% {
        transform: translateY(0%) rotate(360deg);
    }
}