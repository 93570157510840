/* PC, 태블릿 환경을 위한 스타일 */
@media (min-width: 768px) {
    .title {
        margin: 0;
        font-size: 24px;
        font-weight: bold;
    }

    .description {
        font-size: 14px;
        color: var(--sub-font-color);
        margin: 0;
    }

    .terms_container {
        display: flex;
        align-items: center;
    }

    .terms_text {
        font-size: 16px;
    }

    .terms_icon {
        font-size: 26px;
    }

    .next_btn {
        border-radius: 10px;
        border: 0px;
        width: 100%;
        height: 60px;
        color: #ffffff;
        background-color: var(--main-color);
    }

    .next_btn_text {
        font-size: 16px;
    }

    .loader {
        margin: auto;
        border: 2px solid #ffffff;
        border-top: 2px solid var(--main-color);
        border-radius: 50%;
        width: 18px;
        height: 18px;
        animation: spin 2s linear infinite;
    }

    @keyframes spin {
        0% {
            transform: translateY(0%) rotate(0deg);
        }

        100% {
            transform: translateY(0%) rotate(360deg);
        }
    }
}

/* 모바일 환경을 위한 스타일 */
@media (max-width: 767px) {
    .title {
        margin: 0;
        font-size: 22px;
        font-weight: bold;
    }

    .description {
        font-size: 12px;
        color: var(--sub-font-color);
        margin: 0;
    }

    .terms_container {
        display: flex;
        align-items: center;
    }

    .terms_text {
        font-size: 14px;
    }

    .terms_icon {
        font-size: 22px;
    }

    .next_btn {
        border-radius: 10px;
        border: 0px;
        width: 100%;
        height: 60px;
        color: #ffffff;
        background-color: var(--main-color);
    }

    .next_btn_text {
        font-size: 14px;
    }

    .loader {
        margin: auto;
        border: 2px solid #ffffff;
        border-top: 2px solid var(--main-color);
        border-radius: 50%;
        width: 18px;
        height: 18px;
        animation: spin 2s linear infinite;
    }

    @keyframes spin {
        0% {
            transform: translateY(0%) rotate(0deg);
        }

        100% {
            transform: translateY(0%) rotate(360deg);
        }
    }
}