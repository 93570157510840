/* PC, 태블릿 환경을 위한 스타일 */
@media (min-width: 768px) {
    .page {
        text-align: center;
        padding-top: 20px;
    }
    .page_name{
        text-align: start;
        margin: auto;
        width: 440px;
        font-size: 22px;
        font-weight: bold;
    }

    .profile_container {
        display: flex;
        width: 440px;
        margin: auto;
    }

    .profile {
        width: 60px;
        height: 60px;
        border-radius: 50%;
    }

    .info_container {
        display: flex;
        flex-direction: column;
        justify-content: center;

    }

    .name {
        margin: 0;
        text-align: start;
        font-size: 18px;
        font-weight: bold;
    }

    .post_info {
        margin: 0;
        font-size: 16px;
        color: var(--sub-font-color);
    }

    .category_container {
        margin: auto;
        width: 440px;
        display: flex;
        justify-content: flex-start;
    }

    .category_item_btn {
        margin-right: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border: 0px;
        background-color: transparent;
    }

    .category_item_text {
        color: var(--main-font-color);
        font-size: 16px;
        margin: 0;
    }

    .load_more_posts_btn {
        margin: auto;
        border-radius: 10px;
        border: 0px;
        width: 440px;
        height: 60px;
        color: #ffffff;
        background-color: var(--main-color);
        font-size: 16px;
        font-weight: bold;
    }

    .loader {
        margin: auto;
        border: 2px solid #ffffff;
        border-top: 2px solid var(--main-color);
        border-radius: 50%;
        width: 18px;
        height: 18px;
        animation: spin 2s linear infinite;
    }

    @keyframes spin {
        0% {
            transform: translateY(0%) rotate(0deg);
        }

        100% {
            transform: translateY(0%) rotate(360deg);
        }
    }
}

/* 모바일 환경을 위한 스타일 */
@media (max-width: 767px) {
    .page {
        text-align: center;
        padding-top: 20px;
    }
    .page_name{
        text-align: start;
        margin: auto;
        width: auto;
        font-size: 20px;
        font-weight: bold;
    }

    .profile_container {
        display: flex;
        width: auto;
        margin: auto;
    }

    .profile {
        width: 54px;
        height: 54px;
        border-radius: 50%;
    }

    .info_container {
        display: flex;
        flex-direction: column;
        justify-content: center;

    }

    .name {
        margin: 0;
        text-align: start;
        font-size: 16px;
        font-weight: bold;
    }

    .post_info {
        margin: 0;
        font-size: 16px;
        color: var(--sub-font-color);
    }

    .category_container {
        margin: auto;
        width: auto;
        display: flex;
        justify-content: flex-start;
    }

    .category_item_btn {
        margin-right: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border: 0px;
        background-color: transparent;
    }

    .category_item_text {
        color: var(--main-font-color);
        font-size: 3.2vw;
        margin: 0;
    }

    .load_more_posts_btn {
        margin: auto;
        border-radius: 10px;
        border: 0px;
        width: 100%;
        height: 60px;
        color: #ffffff;
        background-color: var(--main-color);
        font-size: 14px;
        font-weight: bold;
    }

    .loader {
        margin: auto;
        border: 2px solid #ffffff;
        border-top: 2px solid var(--main-color);
        border-radius: 50%;
        width: 16px;
        height: 16px;
        animation: spin 2s linear infinite;
    }

    @keyframes spin {
        0% {
            transform: translateY(0%) rotate(0deg);
        }

        100% {
            transform: translateY(0%) rotate(360deg);
        }
    }
}