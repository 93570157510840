/* PC, 태블릿 환경을 위한 스타일 */
@media (min-width: 768px) {
    .container {
        text-align: start;
        margin: auto;
        margin-bottom: 20px;
        width: 400px;
        display: flex;
        flex-direction: column;
        padding: 20px;
        background-color: var(--sub-5-color);
        border-radius: 10px;
        position: relative;
    }

    .to_detail_container{
        display: flex;
        position: absolute;
        top: 25px;
        right: 15px;
        justify-content: center;
        align-items: center;
    }

    .to_detail_text{
        margin: 0;
        font-size: 14px;
    }

    .number_container {
        display: flex;
    }

    .number {
        margin: 0;
        font-size: 24px;
        color: var(--sub-font-color);
        font-weight: bold;
        display: flex;
        align-items: center;
    }

    .title {
        padding: 0px;
        font-size: 18px;
        color: var(--main-font-color);
        border: none;
        width: 100%;
    }

    .answer_container {
        display: flex;
        align-items: center;
        background-color: #ffffff;
        border: none;
        border-radius: 10px;
        width: auto;
        height: 20px;
        padding: 10px;
    }

    .answer_text {
        margin: 0px;
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

/* 모바일 환경을 위한 스타일 */
@media (max-width: 767px) {
    .container {
        text-align: start;
        margin: auto;
        margin-bottom: 20px;
        width: auto;
        display: flex;
        flex-direction: column;
        padding: 20px;
        background-color: var(--sub-5-color);
        border-radius: 10px;
        position: relative;
    }

    .to_detail_container{
        display: flex;
        position: absolute;
        top: 25px;
        right: 15px;
        justify-content: center;
        align-items: center;
    }

    .to_detail_text{
        margin: 0;
        font-size: 14px;
    }

    .number_container {
        display: flex;
    }

    .number {
        margin: 0;
        font-size: 22px;
        color: var(--sub-font-color);
        font-weight: bold;
        display: flex;
        align-items: center;
    }

    .title {
        padding: 0px;
        font-size: 16px;
        color: var(--main-font-color);
        border: none;
        width: 100%;
    }

    .answer_container {
        display: flex;
        align-items: center;
        background-color: #ffffff;
        border: none;
        border-radius: 10px;
        width: auto;
        height: 20px;
        padding: 10px;
    }

    .answer_text {
        margin: 0px;
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}