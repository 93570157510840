/* PC, 태블릿 환경을 위한 스타일 */
@media (min-width: 768px) {
    .page {
        text-align: center;
    }

    .category_container {
        left: 50%; /* 부모(뷰포트)의 가운데 정렬 */
        transform: translateX(-50%); /* 자신의 너비의 절반 만큼 왼쪽으로 이동 */
        padding-top: 20px;
        position: fixed;
        background-color: #ffffff;
        z-index: 200;
        padding-bottom: 10px;
        margin: auto;
        width: 440px;
        display: flex;
        justify-content: flex-start;
    }

    .category_item_btn {
        margin-right: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border: 0px;
        background-color: transparent;
    }
    
    .category_item_text {
        color: var(--main-font-color);
        font-size: 16px;
        margin: 0;
    }

    .post_container{
        padding-top: 30px;
    }
    
    .load_more_posts_btn {
        margin: auto;
        border-radius: 10px;
        border: 0px;
        width: 440px;
        height: 60px;
        color: #ffffff;
        background-color: var(--main-color);
        font-size: 16px;
        font-weight: bold;
    }
    
    .loader {
        margin: auto;
        border: 2px solid #ffffff;
        border-top: 2px solid var(--main-color);
        border-radius: 50%;
        width: 18px;
        height: 18px;
        animation: spin 2s linear infinite;
    }
    
    @keyframes spin {
        0% {
            transform: translateY(0%) rotate(0deg);
        }
    
        100% {
            transform: translateY(0%) rotate(360deg);
        }
    }
}

/* 모바일 환경을 위한 스타일 */
@media (max-width: 767px) {
    .page {
        text-align: center;
    }

    .category_container {
        background-color: #fff;
        display: flex;
        justify-content: flex-start;
        margin: auto;
        padding-bottom: 10px;
        padding-top: 20px;
        padding-left: 30px;
        left: 0;
        position: fixed;
        width: calc(100% - 30px);
        z-index: 200;
    }

    .category_item_btn {
        margin-right: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border: 0px;
        background-color: transparent;
    }
    
    .category_item_text {
        color: var(--main-font-color);
        font-size: 3.2vw;
        margin: 0;
    }

    .post_container{
        padding-top: 10px;
    }
    
    .load_more_posts_btn {
        margin: auto;
        border-radius: 10px;
        border: 0px;
        width: 100%;
        height: 60px;
        color: #ffffff;
        background-color: var(--main-color);
        font-size: 14px;
        font-weight: bold;
    }
    
    .loader {
        margin: auto;
        border: 2px solid #ffffff;
        border-top: 2px solid var(--main-color);
        border-radius: 50%;
        width: 16px;
        height: 16px;
        animation: spin 2s linear infinite;
    }
    
    @keyframes spin {
        0% {
            transform: translateY(0%) rotate(0deg);
        }
    
        100% {
            transform: translateY(0%) rotate(360deg);
        }
    }
}