/* PC, 태블릿 환경을 위한 스타일 */
@media (min-width: 768px) {
    .page{
        text-align: center;
        padding-top: 20px;
    }

    .page_name{
        text-align: start;
        margin: auto;
        width: 440px;
        font-size: 22px;
        font-weight: bold;
    }

    .container {
        margin: auto;
        margin-bottom: 20px;
        width: 400px;
        display: flex;
        flex-direction: column;
        padding: 20px;
        background-color: var(--sub-5-color);
        border-radius: 10px;
    }

    .type {
        text-align: left;
        margin: 0;
        font-size: 14px;
        color: var(--sub-font-color);
    }

    .title_input {
        padding: 0px;
        font-size: 18px;
        color: var(--main-font-color);
        border: none;
        width: 100%;
        background-color: transparent;
    }

    .option_container {
        display: flex;
        padding: 10px;
        margin-bottom: 10px;
        border: 1px solid var(--sub-10-color);
        border-radius: 5px;
    }

    .content_input {
        background-color: transparent;
        font-size: 14px;
        color: var(--main-font-color);
        border: none;
        width: 100%;
        padding: 0px;
    }

    .delete_btn {
        border: none;
        background-color: transparent;
    }
    
    .choice_add_btn{
        border-radius: 5px;
        border: 0px;
        width: 100%;
        height: 60px;
        color: var(--sub-font-color);
        background-color: var(--sub-10-color);
        font-size: 14px;
    }

    .submit_btn {
        margin: auto;
        border-radius: 10px;
        border: 0px;
        width: 440px;
        height: 60px;
        color: #ffffff;
        background-color: var(--main-color);
        font-size: 16px;
    }

    .loader {
        margin: auto;
        border: 2px solid #ffffff;
        border-top: 2px solid var(--main-color);
        border-radius: 50%;
        width: 18px;
        height: 18px;
        animation: spin 2s linear infinite;
    }

    @keyframes spin {
        0% {
            transform: translateY(0%) rotate(0deg);
        }

        100% {
            transform: translateY(0%) rotate(360deg);
        }
    }
}

/* 모바일 환경을 위한 스타일 */
@media (max-width: 767px) {
    .page{
        text-align: center;
        padding-top: 20px;
    }

    .page_name{
        text-align: start;
        margin: auto;
        width: auto;
        font-size: 20px;
        font-weight: bold;
    }

    .container {
        margin: auto;
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        padding: 20px;
        background-color: var(--sub-5-color);
        border-radius: 10px;
    }

    .type {
        text-align: left;
        margin: 0;
        font-size: 14px;
        color: var(--sub-font-color);
    }

    .title_input {
        padding: 0px;
        font-size: 16px;
        color: var(--main-font-color);
        border: none;
        width: 100%;
        background-color: transparent;
    }

    .option_container {
        display: flex;
        padding: 10px;
        margin-bottom: 10px;
        border: 1px solid var(--sub-10-color);
        border-radius: 5px;
    }

    .image_btn {
        width: 40px;
        height: 40px;
        padding: 10px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .content_input {
        background-color: transparent;
        font-size: 14px;
        color: var(--main-font-color);
        border: none;
        width: 100%;
        padding: 0px;
    }

    .delete_btn {
        border: none;
        background-color: transparent;
    }
    
    .choice_add_btn{
        border-radius: 5px;
        border: 0px;
        width: 100%;
        height: 60px;
        color: var(--sub-font-color);
        background-color: var(--sub-10-color);
        font-size: 14px;
    }

    .submit_btn {
        margin: auto;
        border-radius: 10px;
        border: 0px;
        width: 100%;
        height: 60px;
        color: #ffffff;
        background-color: var(--main-color);
        font-size: 14px;
    }

    .loader {
        margin: auto;
        border: 2px solid #ffffff;
        border-top: 2px solid var(--main-color);
        border-radius: 50%;
        width: 18px;
        height: 16px;
        animation: spin 2s linear infinite;
    }

    @keyframes spin {
        0% {
            transform: translateY(0%) rotate(0deg);
        }

        100% {
            transform: translateY(0%) rotate(360deg);
        }
    }
}