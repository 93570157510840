/* PC, 태블릿 환경을 위한 스타일 */
@media (min-width: 768px) {
    .component {
        display: flex;
    }

    .bar_container {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: end;
    }

    .bar_percentage {
        font-size: 14px;
    }

    .bar {
        width: 100%;
        max-width: 50px;
        background-color: var(--sub-5-color);
        border-radius: 5px;
    }

    .bar_label {
        font-size: 14px;
    }
}

/* 모바일 환경을 위한 스타일 */
@media (max-width: 767px) {
    .component {
        display: flex;
    }

    .bar_container {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: end;
    }

    .bar_percentage {
        font-size: 12px;
    }

    .bar {
        width: 100%;
        max-width: 26px;
        background-color: var(--sub-5-color);
        border-radius: 5px;
    }

    .bar_label {
        font-size: 12px;
    }
}