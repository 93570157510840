/* PC, 태블릿 환경을 위한 스타일 */
@media (min-width: 768px) {
    .page{
        width: 440px;
        margin: auto;
    }
    .title{
        font-size: 20px;
        font-weight: bold;
    }
    .content{
        font-size: 16px;
    }
}

/* 모바일 환경을 위한 스타일 */
@media (max-width: 767px) {
    .title{
        font-size: 18px;
        font-weight: bold;
    }
    .content{
        font-size: 14px;
    }
}