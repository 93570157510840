/* PC, 태블릿 환경을 위한 스타일 */
@media (min-width: 768px) {
    .page {
        text-align: start;
    }

    .container {
        margin: auto;
        margin-bottom: 20px;
        width: 400px;
        display: flex;
        flex-direction: column;
        padding: 20px;
        background-color: var(--sub-5-color);
        border-radius: 10px;
        position: relative;
    }

    .to_analytics_page_btn {
        display: flex;
        position: absolute;
        bottom: 20px;
        right: 15px;
        justify-content: center;
        align-items: center;
    }

    .to_analytics_page_btn_text {
        margin: 0;
        font-size: 14px;
    }

    .delete_btn {
        width: fit-content;
        height: fit-content;
        position: absolute;
        right: 15px;    
    }

    .type {
        margin: 0;
        font-size: 14px;
        color: var(--sub-font-color);
    }

    .title {
        margin: 0;
        font-size: 18px;
        font-weight: bold;
    }

    .optionContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 15px;
    }

    .image {
        width: 50px;
        height: 50px;
        object-fit: cover;
        border-radius: 200px;
        margin-right: 10px;
    }

    .image_blank {
        width: 50px;
        height: 50px;
    }

    .optionDetails {
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    .option_text {
        margin: 0px;
        font-size: 14px;
    }

    .contentRow {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .progressBarContainer {
        width: 100%;
        border: 2px solid var(--main-color);
        border-radius: 5px;
    }

    .progress_bar {
        height: 20px;
        background-color: var(--main-color);
    }

    .total_num {
        margin: 0px;
        font-size: 14px;
        color: var(--sub-font-color);
    }
}

/* 모바일 환경을 위한 스타일 */
@media (max-width: 767px) {
    .page {
        text-align: start;
    }

    .container {
        margin: auto;
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        padding: 20px;
        background-color: var(--sub-5-color);
        border-radius: 10px;
        position: relative;
    }

    .to_analytics_page_btn {
        display: flex;
        position: absolute;
        bottom: 20px;
        right: 15px;
        justify-content: center;
        align-items: center;
    }

    .to_analytics_page_btn_text {
        margin: 0;
        font-size: 14px;
    }

    .delete_btn{
        width: fit-content;
        height: fit-content;
        position: absolute;
        right: 15px;    
    }

    .type {
        margin: 0;
        font-size: 14px;
        color: var(--sub-font-color);
    }

    .title {
        margin: 0;
        font-size: 16px;
        font-weight: bold;
    }

    .optionContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 20px;
    }

    .image {
        width: 40px;
        height: 40px;
        object-fit: cover;
        border-radius: 200px;
        margin-right: 10px;
    }

    .image_blank {
        width: 40px;
        height: 40px;
    }

    .optionDetails {
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    .option_text {
        margin: 0px;
        font-size: 14px;
    }

    .contentRow {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .progressBarContainer {
        width: 100%;
        border: 2px solid var(--main-color);
        border-radius: 5px;
    }

    .progress_bar {
        height: 20px;
        background-color: var(--main-color);
    }

    .total_num {
        margin: 0px;
        font-size: 14px;
        color: var(--sub-font-color);
    }
}