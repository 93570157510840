/* PC, 태블릿 환경을 위한 스타일 */
@media (min-width: 768px) {
    .dropdown_container {
        position: relative;
        margin-bottom: 20px;
        width: auto;
        flex-grow: 1;
        flex-basis: 0;
    }
    
    .unselected{
        color: var(--sub-font-color) !important; 
    }
    
    .dropdown_btn {
        color: var(--main-font-color);
        font-size: 18px;
        padding: 10px 20px;
        border: none;
        border-bottom: 1px solid var(--sub-20-color);
        background-color: #fff;
        cursor: pointer;
        width: 100%;
        text-align: left;
        width: 100%;
        height: 50px;
        padding: 0px;
    }
    
    .dropdown_menu {
        color: var(--main-font-color);
        font-size: 18px;
        position: absolute;
        border: 1px solid #ccc;
        background-color: #fff;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
        max-height: 200px;
        overflow-y: auto;
        z-index: 1000;
        top: 100%;
        left: 0;
        scrollbar-width: none;
        list-style-type: none;
        padding: 0;
        margin: 0;
        width: 100%;
    }
    
    .dropdown_menu::-webkit-scrollbar {
        display: none;
    }
    
    .dropdown_item {
        padding: 10px 20px;
        cursor: pointer;
        border-bottom: 1px solid #eee;
        transition: background-color 0.2s;
    }
    
    .dropdown_item:hover {
        background-color: #f5f5f5;
    }
    
    .dropdown_item:last-child {
        border-bottom: none;
    }
}

/* 모바일 환경을 위한 스타일 */
@media (max-width: 767px) {
    .dropdown_container {
        position: relative;
        margin-bottom: 20px;
        width: auto;
        flex-grow: 1;
        flex-basis: 0;
    }
    
    .unselected{
        color: var(--sub-font-color) !important; 
    }
    
    .dropdown_btn {
        color: var(--main-font-color);
        font-size: 16px;
        padding: 10px 20px;
        border: none;
        border-bottom: 1px solid var(--sub-20-color);
        background-color: #fff;
        cursor: pointer;
        width: 100%;
        text-align: left;
        width: 100%;
        height: 50px;
        padding: 0px;
    }
    
    .dropdown_menu {
        color: var(--main-font-color);
        font-size: 16px;
        position: absolute;
        border: 1px solid #ccc;
        background-color: #fff;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
        max-height: 200px;
        overflow-y: auto;
        z-index: 1000;
        top: 100%;
        left: 0;
        scrollbar-width: none;
        list-style-type: none;
        padding: 0;
        margin: 0;
        width: 100%;
    }
    
    .dropdown_menu::-webkit-scrollbar {
        display: none;
    }
    
    .dropdown_item {
        padding: 10px 20px;
        cursor: pointer;
        border-bottom: 1px solid #eee;
        transition: background-color 0.2s;
    }
    
    .dropdown_item:hover {
        background-color: #f5f5f5;
    }
    
    .dropdown_item:last-child {
        border-bottom: none;
    }
}

