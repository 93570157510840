/* PC, 태블릿 환경을 위한 스타일 */
@media (min-width: 768px) {
    .container {
        text-align: start;
        margin: auto;
        margin-bottom: 20px;
        width: 400px;
        display: flex;
        flex-direction: column;
        padding: 20px;
        background-color: var(--sub-5-color);
        border-radius: 10px;
    }

    .type {
        margin: 0;
        font-size: 14px;
        color: var(--sub-font-color);
    }

    .title_input {
        padding: 0px;
        font-size: 18px;
        color: var(--main-font-color);
        border: none;
        width: 100%;
        background-color: transparent;
    }

    .image_uploader_container {
        width: 100%;
        height: 200px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--sub-10-color);
        border-radius: 5px;

    }

    .image_uploader_label {
        width: 100%;
        height: 100%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .image_uploader_image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .submit_btn {
        margin: auto;
        border-radius: 10px;
        border: 0px;
        width: 100%;
        height: 60px;
        color: #ffffff;
        background-color: var(--main-color);
        font-size: 16px;
    }
}

/* 모바일 환경을 위한 스타일 */
@media (max-width: 767px) {
    .container {
        text-align: start;
        margin: auto;
        margin-bottom: 20px;
        width: auto;
        display: flex;
        flex-direction: column;
        padding: 20px;
        background-color: var(--sub-5-color);
        border-radius: 10px;
    }

    .type {
        margin: 0;
        font-size: 14px;
        color: var(--sub-font-color);
    }

    .title_input {
        padding: 0px;
        font-size: 16px;
        color: var(--main-font-color);
        border: none;
        width: 100%;
        background-color: transparent;
    }

    .image_uploader_container {
        width: 100%;
        height: 150px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--sub-10-color);
        border-radius: 5px;

    }

    .image_uploader_label {
        width: 100%;
        height: 100%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .image_uploader_image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .submit_btn {
        margin: auto;
        border-radius: 10px;
        border: 0px;
        width: 100%;
        height: 60px;
        color: #ffffff;
        background-color: var(--main-color);
        font-size: 14px;
    }
}