/* PC, 태블릿 환경을 위한 스타일 */
@media (min-width: 768px) {
    .page{
        text-align: start;
    }
    
    .container{
        margin: auto;
        margin-bottom: 20px;
        width: 400px;
        display: flex;
        flex-direction: column;
        padding: 20px;
        background-color: var(--sub-5-color);
        border-radius: 10px;
        position: relative;
    }

    .to_detail_container{
        display: flex;
        position: absolute;
        top: 25px;
        right: 15px;
        justify-content: center;
        align-items: center;
    }

    .to_detail_text{
        margin: 0;
        font-size: 14px;
    }

    .number_container{
        display: flex;
    }

    .number{
        margin: 0;
        font-size: 24px;
        color: var(--sub-font-color);
        font-weight: bold;
        display: flex;
        align-items: center;
    }

    .title{
        margin: 0;
        font-size: 18px;
        font-weight: bold;
    }

    .option_container{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 20px;
    }

    .option_image{
        width: 50px;
        height: 50px;
        object-fit: cover;
        border-radius: 200px;
        margin-right: 10px;
    }

    .option_image_blank{
        width: 50px;
        height: 50px;
    }

    .option_details{
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    .option_contentRow{
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .option_text{
        margin: 0px;
        font-size: 14px;
    }

    .option_progressBar_container{
        width: 100%;
        border: 2px solid var(--main-color);
        border-radius: 5px;
    }

    .option_progress_bar{
        height: 20px;
        background-color: var(--main-color);
    }

    .total_num{
        margin: 0px;
        font-size: 14px;
        color: var(--sub-font-color);
    }
}

/* 모바일 환경을 위한 스타일 */
@media (max-width: 767px) {
    .page{
        text-align: start;
    }
    
    .container{
        margin: auto;
        margin-bottom: 20px;
        width: auto;
        display: flex;
        flex-direction: column;
        padding: 20px;
        background-color: var(--sub-5-color);
        border-radius: 10px;
        position: relative;
    }

    .to_detail_container{
        display: flex;
        position: absolute;
        top: 25px;
        right: 15px;
        justify-content: center;
        align-items: center;
    }

    .to_detail_text{
        margin: 0;
        font-size: 14px;
    }

    .number_container{
        display: flex;
    }

    .number{
        margin: 0;
        font-size: 22px;
        color: var(--sub-font-color);
        font-weight: bold;
        display: flex;
        align-items: center;
    }

    .title{
        margin: 0;
        font-size: 16px;
        font-weight: bold;
    }

    .option_container{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 20px;
    }

    .option_image{
        width: 40px;
        height: 40px;
        object-fit: cover;
        border-radius: 200px;
        margin-right: 10px;
    }

    .option_image_blank{
        width: 40px;
        height: 40px;
    }

    .option_details{
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    .option_contentRow{
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .option_text{
        margin: 0px;
        font-size: 14px;
    }

    .option_progressBar_container{
        width: 100%;
        border: 2px solid var(--main-color);
        border-radius: 5px;
    }

    .option_progress_bar{
        height: 20px;
        background-color: var(--main-color);
    }

    .total_num{
        margin: 0px;
        font-size: 14px;
        color: var(--sub-font-color);
    }
}