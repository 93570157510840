/* PC, 태블릿 환경을 위한 스타일 */
@media (min-width: 768px) {
    .container{
        text-align: start;
        margin: auto;
        margin-bottom: 20px;
        width: 400px;
        display: flex;
        flex-direction: column;
        padding: 20px;
        background-color: var(--sub-5-color);
        border-radius: 10px;
    }

    .number_container{
        display: flex;
    }

    .prev_btn{
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .number{
        margin: 0;
        font-size: 24px;
        color: var(--sub-font-color);
        font-weight: bold;
        display: flex;
        align-items: center;
    }

    .next_btn{
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .blank_fill{
        flex: 1;
    }

    .question_delete_btn{
        border: none;
        background-color: transparent;
    }

    .title_input{
        padding: 0px;
        font-size: 18px;
        color: var(--main-font-color);
        border: none;
        width: 100%;
        background-color: transparent;
    }

    .option_container{
        display: flex;
        padding: 10px;
        margin-bottom: 10px;
        border: 1px solid var(--sub-10-color);
        border-radius: 5px;
    }

    .content_input{
        background-color: transparent;
        font-size: 14px;
        color: var(--main-font-color);
        border: none;
        width: 100%;
        padding: 0px;
    }

    .delete_btn{
        border: none;
        background-color: transparent;
    }

    .add_btn{
        border-radius: 5px;
        border: 0px;
        width: 100%;
        height: 60px;
        color: var(--sub-font-color);
        background-color: var(--sub-10-color);
        font-size: 14px;
    }
}

/* 모바일 환경을 위한 스타일 */
@media (max-width: 767px) {
    .container{
        text-align: start;
        margin: auto;
        margin-bottom: 20px;
        width: auto;
        display: flex;
        flex-direction: column;
        padding: 20px;
        background-color: var(--sub-5-color);
        border-radius: 10px;
    }

    .number_container{
        display: flex;
    }

    .prev_btn{
        width: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .number{
        margin: 0;
        font-size: 22px;
        color: var(--sub-font-color);
        font-weight: bold;
        display: flex;
        align-items: center;
    }

    .next_btn{
        width: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .blank_fill{
        flex: 1;
    }

    .question_delete_btn{
        border: none;
        background-color: transparent;
    }

    .title_input{
        padding: 0px;
        font-size: 16px;
        color: var(--main-font-color);
        border: none;
        width: 100%;
        background-color: transparent;
    }

    .option_container{
        display: flex;
        padding: 10px;
        margin-bottom: 10px;
        border: 1px solid var(--sub-10-color);
        border-radius: 5px;
    }

    .content_input{
        background-color: transparent;
        font-size: 14px;
        color: var(--main-font-color);
        border: none;
        width: 100%;
        padding: 0px;
    }

    .delete_btn{
        border: none;
        background-color: transparent;
    }

    .add_btn{
        border-radius: 5px;
        border: 0px;
        width: 100%;
        height: 60px;
        color: var(--sub-font-color);
        background-color: var(--sub-10-color);
        font-size: 14px;
    }
}