/* PC, 태블릿 환경을 위한 스타일 */
@media (min-width: 768px) {
    .page {
        text-align: start;
    }

    .container {
        margin: auto;
        margin-bottom: 20px;
        width: 400px;
        display: flex;
        flex-direction: column;
        padding: 20px;
        background-color: var(--sub-5-color);
        border-radius: 10px;
        position: relative;
    }

    .to_analytics_page_btn {
        display: flex;
        position: absolute;
        bottom: 20px;
        right: 15px;
        justify-content: center;
        align-items: center;
    }

    .to_analytics_page_btn_text {
        margin: 0;
        font-size: 14px;
    }

    .delete_btn {
        width: fit-content;
        height: fit-content;
        position: absolute;
        right: 15px;
    }

    .type {
        margin: 0;
        font-size: 14px;
        color: var(--sub-font-color);
    }

    .title {
        margin: 0;
        font-size: 18px;
        font-weight: bold;
    }

    .thumbnail_image {
        width: 100%;
        height: 250px;
        object-fit: cover;
        border-radius: 5px;
    }

    .total_num {
        margin: 0px;
        font-size: 14px;
        color: var(--sub-font-color);
    }

    .next_btn {
        border-radius: 10px;
        border: 0px;
        width: 100%;
        height: 60px;
        color: #ffffff;
        background-color: var(--main-color);
        font-size: 18px;
    }
}

/* 모바일 환경을 위한 스타일 */
@media (max-width: 767px) {
    .page {
        text-align: start;
    }

    .container {
        margin: auto;
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        padding: 20px;
        background-color: var(--sub-5-color);
        border-radius: 10px;
        position: relative;
    }

    .to_analytics_page_btn {
        display: flex;
        position: absolute;
        bottom: 20px;
        right: 15px;
        justify-content: center;
        align-items: center;
    }

    .to_analytics_page_btn_text {
        margin: 0;
        font-size: 14px;
    }

    .delete_btn {
        width: fit-content;
        height: fit-content;
        position: absolute;
        right: 15px;
    }

    .type {
        margin: 0;
        font-size: 14px;
        color: var(--sub-font-color);
    }

    .title {
        margin: 0;
        font-size: 16px;
        font-weight: bold;
    }

    .thumbnail_image {
        width: 100%;
        height: 200px;
        object-fit: cover;
        border-radius: 5px;
    }

    .total_num {
        margin: 0px;
        font-size: 14px;
        color: var(--sub-font-color);
    }

    .next_btn {
        border-radius: 10px;
        border: 0px;
        width: 100%;
        height: 60px;
        color: #ffffff;
        background-color: var(--main-color);
        font-size: 16px;
    }
}