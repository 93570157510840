/* PC, 태블릿 환경을 위한 스타일 */
@media (min-width: 768px) {
    .page{
        padding-top: 20px;
    }
    .page_name{
        text-align: start;
        margin: auto;
        width: 440px;
        font-size: 22px;
        font-weight: bold;
    }
}

/* 모바일 환경을 위한 스타일 */
@media (max-width: 767px) {
    .page{
        padding-top: 20px;
    }
    .page_name{
        text-align: start;
        margin: auto;
        width: auto;
        font-size: 20px;
        font-weight: bold;
    }
}