/* PC, 태블릿 환경을 위한 스타일 */
@media (min-width: 768px) {
  .nav_bar_header {
    position: fixed;
    display: flex;
    justify-content: start;
    align-items: center;
    width: 440px;
    height: 50px;
    background-color: #ffffff;
    z-index: 100;
    top: 0;
    left: 50%; /* 부모(뷰포트)의 가운데 정렬 */
        transform: translateX(-50%); /* 자신의 너비의 절반 만큼 왼쪽으로 이동 */
  }

  .nav_bar_header_logo {
    display: none;
  }

  .nav_bar_header_text{
    display: none;
  }

  .nav_bar_body {
    display: flex;
    flex-direction: column;
    width: 16%;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    background-color: #ffffff;
    border-right: 1px solid var(--sub-10-color);
    padding-left: 20px;
  }

  .nav_logo {
    margin-top: 40px;
    width: 60%;
    margin-bottom: 50px;
  }

  .nav_item_container {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .nav_item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 30px;
    margin-left: 4px;
  }

  .nav_item_profile {
    width: 28px;
    height: 28px;
    object-fit: cover;
    border-radius: 50%;
  }

  .nav_item_text {
    margin: 0;
    margin-left: 20px;
  }

  #last_nav_item {
    margin-top: auto;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .nav_bar_body {
    display: flex;
    flex-direction: column;
    width: 60px;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    background-color: #ffffff;
    border-right: 1px solid var(--sub-10-color);
    padding-left: 20px;
  }

  .nav_item_text {
    display: none;
  }
}

@media (max-width: 767px) {
  .nav_bar_header {
    position: fixed;
    display: flex;
    justify-content: start;
    align-items: center;
    width: 100%;
    height: 50px;
    background-color: #ffffff;
    z-index: 100;
    top: 0;
    left: 0;
  }

  .nav_bar_header_logo {
    height: 80%;
  }

  .nav_bar_header_text{
    font-size: 14px;
    font-weight: bold;
  }

  .nav_bar_body {
    display: flex;
    height: 50px;
    bottom: 0;
    left: 0;
    position: fixed;
    width: 100%;
    background-color: #ffffff;
    justify-content: center;
    align-items: center;
    border-top: 1px solid var(--sub-10-color);
    z-index: 100;
  }

  .nav_logo {
    display: none;
  }

  .nav_item_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 90%;
  }

  .nav_item {
    display: flex;
    flex: 1;
    justify-content: center;
    flex-direction: row;
    align-items: center;
  }

  .nav_item_profile {
    width: 28px;
    height: 28px;
    object-fit: cover;
    border-radius: 50%;
  }

  .nav_item_text {
    display: none;
  }
}