/* PC, 태블릿 환경을 위한 스타일 */
@media (min-width: 768px) {
    .page{
        padding-top: 20px;
    }
    .logo_image {
        width: 300px;
        margin: 0 auto;
        display: block;
        margin-bottom: 100px;
    }

    .title {
        text-align: center;
        font-size: 32px;
        font-weight: bold;
    }

    .description {
        text-align: center;
        font-size: 16px;
        color: var(--sub_50_color);
        margin: 0;
    }

    .kakao_login_btn {
        background-color: #FEE500;
        border-radius: 12px;
        height: 70px;
        width: 80%;
        margin: auto;
    }

    .kakao_login_btn_inner {
        height: 70px;
        padding: 0px 20px;
        flex-flow: row;
        display: flex;
        align-items: center;
    }

    .kakao_login_btn_inner_image {
        height: 33%;
    }

    .kakao_login_btn_inner_text_container {
        width: 100%;
        text-align: center;
    }

    .kakao_login_btn_inner_text {
        font-size: 16px;
        color: rgba(0, 0, 0, 0.85);
    }
}

/* 모바일 환경을 위한 스타일 */
@media (max-width: 767px) {
    .page{
        padding-top: 20px;
    }
    .logo_image {
        width: 200px;
        margin: 0 auto;
        display: block;
        margin-bottom: 50px;
    }

    .title {
        text-align: center;
        font-size: 28px;
        font-weight: bold;
    }

    .description {
        text-align: center;
        font-size: 14px;
        color: var(--sub-50-color);
        margin: 0;
    }

    .kakao_login_btn {
        background-color: #FEE500;
        border-radius: 12px;
        height: 60px;
    }

    .kakao_login_btn_inner {
        height: 60px;
        padding: 0px 20px;
        flex-flow: row;
        display: flex;
        align-items: center;
    }

    .kakao_login_btn_inner_image {
        height: 33%;
    }

    .kakao_login_btn_inner_text_container {
        width: 100%;
        text-align: center;
    }

    .kakao_login_btn_inner_text {
        font-size: 16px;
        color: rgba(0, 0, 0, 0.85);
    }
}
