/* PC, 태블릿 환경을 위한 스타일 */
@media (min-width: 768px) {
    .page {
        text-align: center;
        padding-top: 20px;
    }

    .page_name{
        text-align: start;
        margin: auto;
        width: 440px;
        font-size: 22px;
        font-weight: bold;
    }

    .add_question_btn {
        margin: auto;
        border-radius: 10px;
        border: 0px;
        width: 440px;
        height: 60px;
        color: var(--main-font-color);
        background-color: var(--sub-10-color);
        font-weight: bold;
        font-size: 16px;
    }

    .submit_btn {
        margin: auto;
        border-radius: 10px;
        border: 0px;
        width: 440px;
        height: 60px;
        color: #ffffff;
        background-color: var(--main-color);
        font-size: 16px;
        font-weight: bold;
    }

    .loader {
        margin: auto;
        border: 2px solid #ffffff;
        border-top: 2px solid var(--main-color);
        border-radius: 50%;
        width: 18px;
        height: 18px;
        animation: spin 2s linear infinite;
    }

    @keyframes spin {
        0% {
            transform: translateY(0%) rotate(0deg);
        }

        100% {
            transform: translateY(0%) rotate(360deg);
        }
    }
}

/* 모바일 환경을 위한 스타일 */
@media (max-width: 767px) {
    .page {
        text-align: center;
        padding-top: 20px;
    }

    .page_name{
        text-align: start;
        margin: auto;
        width: auto;
        font-size: 20px;
        font-weight: bold;
    }

    .add_question_btn {
        margin: auto;
        border-radius: 10px;
        border: 0px;
        width: 100%;
        height: 60px;
        color: var(--main-font-color);
        background-color: var(--sub-10-color);
        font-weight: bold;
        font-size: 16px;
    }

    .submit_btn {
        margin: auto;
        border-radius: 10px;
        border: 0px;
        width: 100%;
        height: 60px;
        color: #ffffff;
        background-color: var(--main-color);
        font-size: 16px;
        font-weight: bold;
    }

    .loader {
        margin: auto;
        border: 2px solid #ffffff;
        border-top: 2px solid var(--main-color);
        border-radius: 50%;
        width: 18px;
        height: 18px;
        animation: spin 2s linear infinite;
    }

    @keyframes spin {
        0% {
            transform: translateY(0%) rotate(0deg);
        }

        100% {
            transform: translateY(0%) rotate(360deg);
        }
    }
}