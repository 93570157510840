/* PC, 태블릿 환경을 위한 스타일 */
@media (min-width: 768px) {
    .page {
        text-align: start;
        padding-top: 20px;
    }
    .page_name{
        text-align: start;
        margin: auto;
        width: 440px;
        font-size: 22px;
        font-weight: bold;
    }

    .post_container {
        margin: auto;
        margin-bottom: 20px;
        width: 400px;
        display: flex;
        flex-direction: column;
        padding: 20px;
        background-color: var(--sub-5-color);
        border-radius: 10px;
    }

    .post_type {
        margin: 0;
        font-size: 14px;
        color: var(--sub-font-color);
    }

    .number_container {
        display: flex;
    }

    .number {
        margin: 0;
        font-size: 24px;
        color: var(--sub-font-color);
        font-weight: bold;
        display: flex;
        align-items: center;
    }

    .post_title {
        margin: 0;
        font-size: 18px;
        font-weight: bold;
    }

    .post_option_container {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 20px;
    }

    .post_option_image {
        width: 50px;
        height: 50px;
        object-fit: cover;
        border-radius: 200px;
        margin-right: 10px;
    }

    .post_option_image_blank {
        width: 50px;
        height: 50px;
    }

    .post_option_details {
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    .post_option_contentRow {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .post_option_text {
        margin: 0px;
        font-size: 14px;
    }

    .post_option_progressBar_container {
        width: 100%;
        border: 2px solid var(--main-color);
        border-radius: 5px;
    }

    .post_option_progress_bar {
        height: 20px;
        background-color: var(--main-color);
    }

    .to_analytics_btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 120px;
        height: 30px;
        border-radius: 10px;
        border: solid 1px var(--sub-10-color);
        margin-bottom: 20px;
    }

    .to_analytics_btn_text {
        margin: 0px;
        font-size: 12px;
        color: var(--sub-font-color);
    }

    .total_num {
        margin: 0px;
        font-size: 14px;
        color: var(--sub-font-color);
    }
}

/* 모바일 환경을 위한 스타일 */
@media (max-width: 767px) {
    .page {
        text-align: start;
        padding-top: 20px;
    }
    .page_name{
        text-align: start;
        margin: auto;
        width: auto;
        font-size: 20px;
        font-weight: bold;
    }

    .post_container {
        margin: auto;
        margin-bottom: 20px;
        width: auto;
        display: flex;
        flex-direction: column;
        padding: 20px;
        background-color: var(--sub-5-color);
        border-radius: 10px;
    }

    .post_type {
        margin: 0;
        font-size: 14px;
        color: var(--sub-font-color);
    }

    .number_container {
        display: flex;
    }

    .number {
        margin: 0;
        font-size: 22px;
        color: var(--sub-font-color);
        font-weight: bold;
        display: flex;
        align-items: center;
    }

    .post_title {
        margin: 0;
        font-size: 16px;
        font-weight: bold;
    }

    .post_option_container {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 20px;
    }

    .post_option_image {
        width: 40px;
        height: 40px;
        object-fit: cover;
        border-radius: 200px;
        margin-right: 10px;
    }

    .post_option_image_blank {
        width: 40px;
        height: 40px;
    }

    .post_option_details {
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    .post_option_contentRow {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .post_option_text {
        margin: 0px;
        font-size: 14px;
    }

    .post_option_progressBar_container {
        width: 100%;
        border: 2px solid var(--main-color);
        border-radius: 5px;
    }

    .post_option_progress_bar {
        height: 20px;
        background-color: var(--main-color);
    }

    .to_analytics_btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 120px;
        height: 30px;
        border-radius: 10px;
        border: solid 1px var(--sub-10-color);
        margin-bottom: 20px;
    }

    .to_analytics_btn_text {
        margin: 0px;
        font-size: 12px;
        color: var(--sub-font-color);
    }

    .total_num {
        margin: 0px;
        font-size: 14px;
        color: var(--sub-font-color);
    }
}