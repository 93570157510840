/* PC, 태블릿 환경을 위한 스타일 */
@media (min-width: 768px) {
    .page{
        padding-top: 20px;
    }
    .page_name{
        text-align: start;
        margin: auto;
        width: 440px;
        font-size: 22px;
        font-weight: bold;
    }
    .btn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: auto;
        border-radius: 10px;
        border: 0px;
        width: 440px;
        height: 60px;
        color: var(--main-font-color);
        background-color: var(--sub-10-color);
        font-weight: bold;
        font-size: 16px;
    }

    .btn_text {
        margin: 0;
    }
}

/* 모바일 환경을 위한 스타일 */
@media (max-width: 767px) {
    .page{
        padding-top: 20px;
    }
    .page_name{
        text-align: start;
        margin: auto;
        width: auto;
        font-size: 20px;
        font-weight: bold;
    }
    .btn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: auto;
        border-radius: 10px;
        border: 0px;
        width: 100%;
        height: 60px;
        color: var(--main-font-color);
        background-color: var(--sub-10-color);
        font-weight: bold;
        font-size: 14px;
    }

    .btn_text {
        margin: 0;
    }
}