/* PC, 태블릿 환경을 위한 스타일 */
@media (min-width: 768px) {
    .page {
        text-align: center;
        padding-top: 20px;
    }
    .page_name{
        text-align: start;
        margin: auto;
        width: 440px;
        font-size: 22px;
        font-weight: bold;
    }

    .profile_container {
        display: flex;
        width: 440px;
        margin: auto;
    }

    .profile_image {
        width: 60px;
        height: 60px;
        border-radius: 50%;
    }

    .profile_info_container {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .profile_info_name {
        margin: 0;
        text-align: start;
        font-size: 18px;
        font-weight: bold;
    }

    .profile_info_info {
        margin: 0;
        font-size: 14px;
        color: var(--sub-font-color);
    }

    .navigate_btn_container {
        width: 440px;
        display: flex;
        flex-direction: row;
        margin: auto;
    }

    .prev_btn {
        border-radius: 10px;
        border: 0px;
        width: auto;
        flex-grow: 1;
        height: 60px;
        color: var(--sub-font-color);
        background-color: var(--sub-10-color);
        font-size: 18px;
    }

    .page_num {
        width: 150px;
        font-size: 18px;
        font-weight: bold;
    }

    .next_btn {
        border-radius: 10px;
        border: 0px;
        width: auto;
        flex-grow: 1;
        height: 60px;
        color: white;
        background-color: var(--main-color);
        font-size: 18px;
    }
}

/* 모바일 환경을 위한 스타일 */
@media (max-width: 767px) {
    .page {
        text-align: center;
        padding-top: 20px;
    }
    .page_name{
        text-align: start;
        margin: auto;
        width: auto;
        font-size: 20px;
        font-weight: bold;
    }

    .profile_container {
        display: flex;
        width: auto;
        margin: auto;
    }

    .profile_image {
        width: 50px;
        height: 50px;
        border-radius: 50%;
    }

    .profile_info_container {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .profile_info_name {
        margin: 0;
        text-align: start;
        font-size: 16px;
        font-weight: bold;
    }

    .profile_info_info {
        margin: 0;
        font-size: 14px;
        color: var(--sub-font-color);
    }

    .navigate_btn_container {
        width: auto;
        display: flex;
        flex-direction: row;
        margin: auto;
    }

    .prev_btn {
        border-radius: 10px;
        border: 0px;
        width: auto;
        flex-grow: 1;
        height: 50px;
        color: var(--sub-font-color);
        background-color: var(--sub-10-color);
        font-size: 16px;
    }

    .page_num {
        width: 120px;
        font-size: 16px;
        font-weight: bold;
    }

    .next_btn {
        border-radius: 10px;
        border: 0px;
        width: auto;
        flex-grow: 1;
        height: 50px;
        color: white;
        background-color: var(--main-color);
        font-size: 16px;
    }
}