/* PC, 태블릿 환경을 위한 스타일 */
@media (min-width: 768px) {
    .description {
        margin: 0;
        font-size: 18px;
        text-align: center;
        color: var(--sub-font-color);
    }

    .main_message {
        margin: 0;
        white-space: pre-line;
        font-size: 26px;
        font-weight: bold;
        text-align: center;
    }

    .next_btn {
        border-radius: 10px;
        border: 0px;
        width: 100%;
        height: 60px;
        color: #ffffff;
        background-color: var(--main-color);
        font-size: 16px;
    }
}

/* 모바일 환경을 위한 스타일 */
@media (max-width: 767px) {
    .description {
        margin: 0;
        font-size: 16px;
        text-align: center;
        color: var(--sub-font-color);
    }

    .main_message {
        margin: 0;
        white-space: pre-line;
        font-size: 24px;
        font-weight: bold;
        text-align: center;
    }

    .next_btn {
        border-radius: 10px;
        border: 0px;
        width: 100%;
        height: 60px;
        color: #ffffff;
        background-color: var(--main-color);
        font-size: 14px;
    }
}