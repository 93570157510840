/* PC, 태블릿 환경을 위한 스타일 */
@media (min-width: 768px) {
    .component {
        display: flex;
        flex-direction: column;
    }

    .bar_container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: start;
        margin-bottom: 20px;
    }

    .bar_percentage {
        font-size: 14px;
    }

    .bar {
        height: 50px;
        background-color: var(--sub-5-color);
        border-radius: 5px;
    }

    .bar_label {
        width: 150px;
        font-size: 14px;
    }
}

/* 모바일 환경을 위한 스타일 */
@media (max-width: 767px) {
    .component {
        display: flex;
        flex-direction: column;
    }

    .bar_container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: start;
    }

    .bar_percentage {
        font-size: 12px;
    }

    .bar {
        height: 26px;
        background-color: var(--sub-5-color);
        border-radius: 5px;
    }

    .bar_label {
        width: 80px;
        font-size: 12px;
    }
}