/* 전역 변수 */
:root {
  --main-font-color: #333D4B;
  --sub-font-color: rgba(51, 61, 75, 0.5);
  --main-color: #0A0A0A;
  --sub-50-color: rgba(10, 10, 10, 0.5);
  --sub-30-color: rgba(10, 10, 10, 0.3);
  --sub-20-color: rgba(10, 10, 10, 0.2);
  --sub-10-color: rgba(10, 10, 10, 0.1);
  --sub-5-color: rgba(10, 10, 10, 0.05);
}

@font-face {
  font-family: 'Pretendard';
  src: url('./fonts/pretendard/Pretendard-Regular.woff') format('woff');
  font-weight: 400; /* Normal weight */
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
  src: url('./fonts/pretendard/Pretendard-Bold.woff') format('woff');
  font-weight: 700; /* Bold weight */
  font-style: normal;
}

/* 기본 스타일 */
body {
  font-family: 'Pretendard';
  margin: 0;
  padding: 30px;
  padding-top: 0px;
  padding-bottom: 50px;
  color: var(--main-font-color);
  background-color: #ffffff;
}

button{
  font-family: 'Pretendard'
}

input{
  font-family: 'Pretendard'
}

textarea{
  font-family: 'Pretendard'
}

/* blanck 스타일 */
.blank-100 {
  width: 100px;
  height: 100px;
}

.blank-80 {
  width: 80px;
  height: 80px;
}

.blank-50 {
  width: 50px;
  height: 50px;
}

.blank-40 {
  width: 40px;
  height: 40px;
}

.blank-30 {
  width: 30px;
  height: 30px;
}

.blank-20 {
  width: 20px;
  height: 20px;
}

.blank-10 {
  width: 10px;
  height: 10px;
}

.blank-5 {
  width: 5px;
  height: 5px;
}

/* input, select, option 스타일 */
input {
  color: var(--main-font-color);
  border: none;
  border-bottom: 1px solid var(--sub-20-color);
  width: 100%;
  padding: 8px 0;
}

::placeholder {
  color: var(--sub-font-color);
}

input:focus {
  outline: none;
}

/* PC, 태블릿 환경을 위한 스타일 */
@media (min-width: 768px) {
  body {
    margin: auto;
    width: 600px;
  }
}

/* 모바일 환경을 위한 스타일 */
@media (max-width: 767px) {
  body {
    margin: 0;
  }
}