/* PC, 태블릿 환경을 위한 스타일 */
@media (min-width: 768px) {
    .container {
        text-align: start;
        margin: auto;
        margin-bottom: 20px;
        width: 400px;
        display: flex;
        flex-direction: column;
        padding: 20px;
        background-color: var(--sub-5-color);
        border-radius: 10px;
    }

    .number_container {
        display: flex;
    }

    .prev_btn {
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .number {
        margin: 0;
        font-size: 24px;
        color: var(--sub-font-color);
        font-weight: bold;
        display: flex;
        align-items: center;
    }

    .next_btn {
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .blank_fill{
        flex: 1;
    }

    .question_delete_btn{
        border: none;
        background-color: transparent;
    }

    .title_input {
        padding: 0px;
        font-size: 18px;
        color: var(--main-font-color);
        border: none;
        width: 100%;
        background-color: transparent;
    }

    .input_container {
        background-color: #ffffff;
        color: var(--sub-font-color);
        border: none;
        border-radius: 10px;
        width: 100%;
        height: 150px;
        padding: 10px;
        box-sizing: border-box;
        resize: none;
        overflow: auto;
    }

    .input_container_text {
        margin: 0px;
        font-size: 14px;
    }
}

/* 모바일 환경을 위한 스타일 */
@media (max-width: 767px) {
    .container {
        text-align: start;
        margin: auto;
        margin-bottom: 20px;
        width: auto;
        display: flex;
        flex-direction: column;
        padding: 20px;
        background-color: var(--sub-5-color);
        border-radius: 10px;
    }

    .number_container {
        display: flex;
    }

    .prev_btn {
        width: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .number {
        margin: 0;
        font-size: 22px;
        color: var(--sub-font-color);
        font-weight: bold;
        display: flex;
        align-items: center;
    }

    .next_btn {
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .blank_fill{
        flex: 1;
    }

    .question_delete_btn{
        border: none;
        background-color: transparent;
    }


    .title_input {
        padding: 0px;
        font-size: 16px;
        color: var(--main-font-color);
        border: none;
        width: 100%;
        background-color: transparent;
    }

    .input_container {
        background-color: #ffffff;
        color: var(--sub-font-color);
        border: none;
        border-radius: 10px;
        width: 100%;
        height: 150px;
        padding: 10px;
        box-sizing: border-box;
        resize: none;
        overflow: auto;
    }

    .input_container_text {
        margin: 0px;
        font-size: 14px;
    }
}