/* PC, 태블릿 환경을 위한 스타일 */
@media (min-width: 768px) {
  .modal_overlay {
    
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 200;
  }

  .modal_content {
    background-color: #fff;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  }

  .modal_content h2 {
    margin-top: 0;
    font-size: 20px;
  }

  .btn_container {
    display: flex;
  }

  .modal_content button {
    background-color: var(--main-color);
    color: #ffffff;
    font-size: 18px;
    font-weight: bold;
    border: none;
    border-radius: 10px;
    flex: 1;
    height: 60px;
  }
}

/* 모바일 환경을 위한 스타일 */
@media (max-width: 767px) {
  .modal_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 200;
  }

  .modal_content {
    width: 60%;
    background-color: #fff;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  }

  .modal_content h2 {
    margin-top: 0;
    font-size: 18px;
  }

  .btn_container {
    display: flex;
  }

  .modal_content button {
    background-color: var(--main-color);
    color: #ffffff;
    font-size: 16px;
    font-weight: bold;
    border: none;
    border-radius: 10px;
    flex: 1;
    height: 60px;
  }
}