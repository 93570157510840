/* PC, 태블릿 환경을 위한 스타일 */
@media (min-width: 768px) {
    .page {
        text-align: start;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 20px;
    }
    .page_name{
        text-align: start;
        margin: auto;
        width: 440px;
        font-size: 22px;
        font-weight: bold;
    }

    .thumbnail_container {
        margin: auto;
        margin-bottom: 20px;
        width: 400px;
        display: flex;
        flex-direction: column;
        padding: 20px;
        background-color: var(--sub-5-color);
        border-radius: 10px;
    }

    .thumbnail_type {
        margin: 0;
        font-size: 14px;
        color: var(--sub-font-color);
    }

    .thumbnail_title {
        margin: 0;
        font-size: 18px;
        font-weight: bold;
    }

    .thumbnail_image {
        width: 100%;
        height: 250px;
        object-fit: cover;
        border-radius: 5px;
    }

    .thumbnail_total_num {
        margin: 0px;
        font-size: 14px;
        color: var(--sub-font-color);
    }

    .to_survey_individual_view {
        margin-bottom: 20px;
        border-radius: 10px;
        border: 0px;
        width: 440px;
        height: 60px;
        color: #ffffff;
        background-color: var(--main-color);
        font-size: 14px;
    }
}

/* 모바일 환경을 위한 스타일 */
@media (max-width: 767px) {
    .page {
        text-align: start;
        padding-top: 20px;
    }
    .page_name{
        text-align: start;
        margin: auto;
        width: auto;
        font-size: 20px;
        font-weight: bold;
    }

    .thumbnail_container {
        margin: auto;
        margin-bottom: 20px;
        width: auto;
        display: flex;
        flex-direction: column;
        padding: 20px;
        background-color: var(--sub-5-color);
        border-radius: 10px;
    }

    .thumbnail_type {
        margin: 0;
        font-size: 14px;
        color: var(--sub-font-color);
    }

    .thumbnail_title {
        margin: 0;
        font-size: 16px;
        font-weight: bold;
    }

    .thumbnail_image {
        width: 100%;
        height: 150px;
        object-fit: cover;
        border-radius: 5px;
    }

    .thumbnail_total_num {
        margin: 0px;
        font-size: 14px;
        color: var(--sub-font-color);
    }

    .to_survey_individual_view {
        margin-bottom: 20px;
        border-radius: 10px;
        border: 0px;
        width: 100%;
        height: 60px;
        color: #ffffff;
        background-color: var(--main-color);
        font-size: 14px;
    }
}