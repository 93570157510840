/* PC, 태블릿 환경을 위한 스타일 */
@media (min-width: 768px) {
  .custom_checkbox {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  
  .checkbox_icon {
    font-size: 26px; /* 아이콘 크기 조정 */
    color: var(--main-color); /* 아이콘 색상 조정 */
  }
}

/* 모바일 환경을 위한 스타일 */
@media (max-width: 767px) {
  .custom_checkbox {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  
  .checkbox_icon {
    font-size: 22px; /* 아이콘 크기 조정 */
    color: var(--main-color); /* 아이콘 색상 조정 */
  }
}