/* PC, 태블릿 환경을 위한 스타일 */
@media (min-width: 768px) {
    .container {
        margin: auto;
        margin-bottom: 20px;
        width: 400px;
        display: flex;
        flex-direction: column;
        padding: 20px;
        background-color: var(--sub-5-color);
        border-radius: 10px;
    }

    .num_container {
        display: flex;
    }

    .num {
        margin: 0;
        font-size: 24px;
        color: var(--sub-font-color);
        font-weight: bold;
    }

    .blank {
        flex: 1;
    }

    .left_num {
        margin: 0;
        font-size: 14px;
        color: var(--sub-font-color);
    }

    .title {
        margin: 0;
        font-size: 18px;
        font-weight: bold;
    }

    .answer_input {
        font-size: 14px;
        color: var(--main-font-color);
        border: none;
        border-radius: 10px;
        width: 100%;
        height: 150px;
        padding: 10px;
        box-sizing: border-box;
        resize: none;
        overflow: auto;
    }

    .answer_input::-webkit-scrollbar {
        display: none;
    }

    .answer_input {
        scrollbar-width: none;
    }


    textarea:focus {
        outline: none;
    }

    .btn_container {
        display: flex;
    }

    .prev_btn {
        border-radius: 10px;
        border: 0px;
        flex: 1;
        height: 60px;
        color: var(--main-font-color);
        background-color: var(--sub-10-color);
        font-size: 18px;
    }

    .next_btn {
        border-radius: 10px;
        border: 0px;
        flex: 1;
        height: 60px;
        color: #ffffff;
        background-color: var(--main-color);
        font-size: 18px;
    }
}

/* 모바일 환경을 위한 스타일 */
@media (max-width: 767px) {
    .container {
        margin: auto;
        margin-bottom: 20px;
        width: auto;
        display: flex;
        flex-direction: column;
        padding: 20px;
        background-color: var(--sub-5-color);
        border-radius: 10px;
    }

    .num_container {
        display: flex;
    }

    .num {
        margin: 0;
        font-size: 22px;
        color: var(--sub-font-color);
        font-weight: bold;
    }

    .blank {
        flex: 1;
    }

    .left_num {
        margin: 0;
        font-size: 14px;
        color: var(--sub-font-color);
    }

    .title {
        margin: 0;
        font-size: 16px;
        font-weight: bold;
    }

    .answer_input {
        font-size: 14px;
        color: var(--main-font-color);
        border: none;
        border-radius: 10px;
        width: 100%;
        height: 150px;
        padding: 10px;
        box-sizing: border-box;
        resize: none;
        overflow: auto;
    }

    .answer_input::-webkit-scrollbar {
        display: none;
    }

    .answer_input {
        scrollbar-width: none;
    }


    textarea:focus {
        outline: none;
    }

    .btn_container {
        display: flex;
    }

    .prev_btn {
        border-radius: 10px;
        border: 0px;
        flex: 1;
        height: 60px;
        color: var(--main-font-color);
        background-color: var(--sub-10-color);
        font-size: 16px;
    }

    .next_btn {
        border-radius: 10px;
        border: 0px;
        flex: 1;
        height: 60px;
        color: #ffffff;
        background-color: var(--main-color);
        font-size: 16px;
    }
}